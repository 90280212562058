import { ProduktSlider } from "thalia-molecules/produktlisten/slider";

class SucheProduktslider extends HTMLElement {
    constructor() {
        super();
        this.componentName = this.getAttribute("component");
        this.sliderContainer = this.querySelector(".tm-produktliste");
        this.produktSlider = new ProduktSlider(this.sliderContainer);
    }

    get hbdlImAboVerfuegbar() {
        return this.hasAttribute("hbdl-im-abo-verfuegbar");
    }

    async connectedCallback() {
        try {
            this.produktSlider.init();
            if (this.hbdlImAboVerfuegbar) {
                await import(/* webpackChunkName: "abo-preisinformation" */ "../../modules/abo-preisinformation");
            }
        } catch (error) {
            console.error("SucheProduktslider", "suche-produktslider", error);
        }
    }
}

if (!customElements.get("suche-produktslider")) {
    customElements.define("suche-produktslider", SucheProduktslider);
}
